.App {
  text-align: center;
  font-family: 'Raleway', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #ef9ea8;
  font-size: 30px;
  text-decoration: none;
}


h1 {
  color: #df3d51;
  font-size: 30px;
  padding-left: 4rem;
  margin-bottom: 2rem;
}

tspan {
    font-size: 12px;
}

.recharts-tooltip-wrapper {
    padding: 0;
    font-size: 18px;
    line-height: 1;
}
